<template>
	<b-modal
		id="change-password"
		title="Change Password"
		ref="modal"
		ok-title="Submit"
		ok-variant="success"
		@ok.prevent="handleOk"
		@show="onReset"
		:ok-disabled="isLoading"
		no-close-on-backdrop
		no-close-on-esc
		hide-header-close
		ok-only
	>
		<b-container fluid>
			<loading
				:active.sync="isLoading"
				loader="spinner"
				color="#20A8D8"
				:is-full-page="false"
			/>

			<b-form novalidate>
				<p class="text-muted text-left">
					Username: <b>{{ emailAddress }}</b>
				</p>

				<b-alert show variant="warning">
					<i class="fa fa-exclamation-circle"></i>&nbsp; You need to relogin
					after you change your password.
				</b-alert>

				<b-input-group class="mb-3 input-with-validation">
					<b-input-group-prepend>
						<b-input-group-text>
							<i class="icon-lock"></i>
						</b-input-group-text>
					</b-input-group-prepend>
					<input
						type="password"
						name="Password"
						v-model="form.password"
						v-validate="{
							required: true,
							regex: passwordRegex,
						}"
						class="form-control"
						placeholder="Password"
						autocomplete="off"
						ref="password"
					/>
				</b-input-group>
				<span v-if="errors.has('Password')" class="help-block">{{
					passwordErrorMsg
				}}</span>

				<b-input-group class="mb-4 input-with-validation">
					<b-input-group-prepend>
						<b-input-group-text>
							<i class="icon-lock"></i>
						</b-input-group-text>
					</b-input-group-prepend>
					<input
						type="password"
						name="Confirm Password"
						v-model="form.confirmPassword"
						v-validate="{
							required: true,
							regex: passwordRegex,
						}"
						class="form-control"
						placeholder="Confirm Password"
						autocomplete="off"
					/>
				</b-input-group>
				<span v-if="errors.has('Confirm Password')" class="help-block"
					>{{ errors.first('Confirm Password') }} &nbsp;</span
				>
			</b-form>
		</b-container>
	</b-modal>
</template>

<script>
import { firebase, db } from '@/config/firebase';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'change-password',
	components: { Loading },
	data() {
		return {
			isLoading: false,
			emailAddress: '',
			form: {
				password: '',
				confirmPassword: '',
			},
		};
	},
	computed: {
		passwordRegex() {
			return config.passwordRegex;
		},
		passwordErrorMsg() {
			return config.passwordErrorMsg;
		},
	},
	mounted() {
		let currUser = this.$store.getters.loggedUser;
		this.emailAddress = currUser.emailAddress;
	},
	methods: {
		async handleOk() {
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				this.isLoading = false;
				return;
			}

			let user = firebase.auth().currentUser;
			user.updatePassword(this.form.password).then(
				async () => {
					// Update user isNew False
					let userRef = db.collection('users').doc(user.email);
					await userRef.set({ isNewUser: false }, { merge: true });

					// Logout
					await this.$store.dispatch('logout');
					let previousState = this.$store.getters.previousState;
					if (!_.isEmpty(previousState)) {
						this.$store.replaceState(previousState);
					}

					this.$router.push('/login');

					// Prompt relogin with new password
					this.$toaster.success(
						'Password was successfully updated. Please re-login your account.'
					);
					this.isLoading = false;
				},
				(_error) => {
					this.$toaster.warning('Error updating password. Please try again.');
					this.isLoading = false;
				}
			);
		},
		onReset() {
			this.form.password = '';
			this.form.confirmPassword = '';
		},
	},
};
</script>
