export default {
	items: [
		{
			name: 'Dashboard',
			url: '/admin/dashboard',
			icon: 'icon-speedometer',
			badge: {
				variant: 'primary',
			},
		},
		{
			name: 'Account Setup',
			url: '/setup',
			icon: 'icon-settings',
			children: [
				{
					name: 'Company',
					url: '/admin/company',
					icon: 'fa fa-building-o',
				},
				{
					name: 'Storage Location',
					url: '/admin/admin-location',
					icon: 'icon-location-pin',
				},
				{
					name: 'User',
					url: '/admin/admin-user',
					icon: 'icon-people',
				},
				{
					name: 'Connection',
					url: '/admin/admin-connection',
					icon: 'fa fa-connectdevelop',
				},
				{
					name: 'Transportation',
					url: '/admin/admin-transportation',
					icon: 'fa fa-truck',
				},
			],
		},
		{
			name: 'Asset Management',
			url: '/asset',
			icon: 'fa fa-tasks',
			children: [
				{
					name: 'Asset Type',
					url: '/admin/admin-asset-type',
					icon: 'fa fa-cube',
				},
				{
					name: 'Asset Pool Distribution',
					url: '/admin/admin-asset-pool-distribution',
					icon: 'fa fa-cubes',
				},
			],
		},
		{
			name: 'Transactions',
			url: '/transaction',
			icon: 'icon-book-open',
			children: [
				{
					name: 'Dispatch',
					url: '/admin/admin-dispatch',
					icon: 'fa fa-upload',
				},
				{
					name: 'Receipt',
					url: '/admin/admin-receipt',
					icon: 'fa fa-download',
				},
				{
					name: 'Maintenance',
					url: '/admin/admin-maintenance',
					icon: 'icon-wrench',
				},
			],
		},
		{
			name: 'Reports',
			url: '/reports',
			icon: 'fa fa-line-chart',
			children: [
				{
					name: 'Inventory Summary',
					url: '/admin/admin-inventory-summary',
					icon: 'fa fa-bar-chart',
				},
				{
					name: 'Assets with Truckers',
					url: '/admin/admin-assets-with-truckers',
					icon: 'fa fa-truck',
				},
				{
					name: 'Incident Report',
					url: '/admin/admin-incident-reports',
					icon: 'fa fa-warning'
				},
				{
					name: 'Dispatch Alert Logs',
					url: '/admin/admin-dispatch-alerts',
					icon: 'fa fa-bell',
				},
				{
					name: 'Asset Accountability',
					url: '/admin/admin-asset-accountability',
					icon: 'fa fa-pie-chart'
				},
			],
		},
		{
			name: 'Support',
			url: '/support',
			icon: 'fa fa-handshake-o',
			children: [
				{
					name: 'Transfer Company',
					url: '/admin/admin-transfer-company-requests',
					icon: 'fa fa-exchange',
				},
			],
		},
		{
			name: 'Data Importer',
			url: '/data-importer',
			icon: 'icon-docs',
			children: [
				{
					name: 'Company Importer',
					url: '/admin/company-importer',
					icon: 'icon-docs',
				},
				{
					name: 'Location Importer',
					url: '/admin/storage-location-importer',
					icon: 'icon-docs',
				},
				{
					name: 'User Importer',
					url: '/admin/user-importer',
					icon: 'icon-docs',
				},
				{
					name: 'Transportation Importer',
					url: '/admin/transportation-importer',
					icon: 'icon-docs',
				},
			],
		},
	],
};
