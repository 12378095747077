var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{ref:"modal",attrs:{"id":"change-password","title":"Change Password","ok-title":"Submit","ok-variant":"success","ok-disabled":_vm.isLoading,"no-close-on-backdrop":"","no-close-on-esc":"","hide-header-close":"","ok-only":""},on:{"ok":function($event){$event.preventDefault();return _vm.handleOk.apply(null, arguments)},"show":_vm.onReset}},[_c('b-container',{attrs:{"fluid":""}},[_c('loading',{attrs:{"active":_vm.isLoading,"loader":"spinner","color":"#20A8D8","is-full-page":false},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('b-form',{attrs:{"novalidate":""}},[_c('p',{staticClass:"text-muted text-left"},[_vm._v(" Username: "),_c('b',[_vm._v(_vm._s(_vm.emailAddress))])]),_c('b-alert',{attrs:{"show":"","variant":"warning"}},[_c('i',{staticClass:"fa fa-exclamation-circle"}),_vm._v("  You need to relogin after you change your password. ")]),_c('b-input-group',{staticClass:"mb-3 input-with-validation"},[_c('b-input-group-prepend',[_c('b-input-group-text',[_c('i',{staticClass:"icon-lock"})])],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"},{name:"validate",rawName:"v-validate",value:({
						required: true,
						regex: _vm.passwordRegex,
					}),expression:"{\n\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\tregex: passwordRegex,\n\t\t\t\t\t}"}],ref:"password",staticClass:"form-control",attrs:{"type":"password","name":"Password","placeholder":"Password","autocomplete":"off"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "password", $event.target.value)}}})],1),(_vm.errors.has('Password'))?_c('span',{staticClass:"help-block"},[_vm._v(_vm._s(_vm.passwordErrorMsg))]):_vm._e(),_c('b-input-group',{staticClass:"mb-4 input-with-validation"},[_c('b-input-group-prepend',[_c('b-input-group-text',[_c('i',{staticClass:"icon-lock"})])],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.confirmPassword),expression:"form.confirmPassword"},{name:"validate",rawName:"v-validate",value:({
						required: true,
						regex: _vm.passwordRegex,
					}),expression:"{\n\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\tregex: passwordRegex,\n\t\t\t\t\t}"}],staticClass:"form-control",attrs:{"type":"password","name":"Confirm Password","placeholder":"Confirm Password","autocomplete":"off"},domProps:{"value":(_vm.form.confirmPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "confirmPassword", $event.target.value)}}})],1),(_vm.errors.has('Confirm Password'))?_c('span',{staticClass:"help-block"},[_vm._v(_vm._s(_vm.errors.first('Confirm Password'))+"  ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }